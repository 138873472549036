import React from 'react';
import { Link } from 'gatsby';

import Layout from '@components/layout';
import SEO from '@components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Page not found" />
    <div className="container">
      <div className="row py-5">
        <div className="col-12">
          <h1>Page not found</h1>
          <Link to={'/'}>Home</Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
